<template>
  <div class="my-page">
    <card-header ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <notification-copy v-if="isShowNotification" :toastText="toastText" />
      <div class="user-summary text-center">
        <UserSummary
          :propsUser="user_info"
          :propsType="typeSingle"
          :propsButton="false"
          :propsSex="1"
        />
      </div>
      <!-- <div class="my-info">
        <div>
          <span
            class="my-name f-w6"
            v-if="user_info.last_name_kanji && user_info.first_name_kanji"
            >{{ user_info.last_name_kanji + user_info.first_name_kanji }}</span
          >
        </div>
      </div> -->
      <div
        class="position-relative d-flex justify-content-center align-items-center wrapper-btn"
      >
        <!--        <div-->
        <!--          class="my-icon position-relative btn-order"-->
        <!--          @click="$router.push('/create-order')"-->
        <!--        >-->
        <!--          <span class="f-w6 mb-0">Superオファーを出す</span>-->
        <!--        </div>-->
        <div
          class="my-icon position-relative"
          @click="checkIdentityCreditCard()"
        >
          <!--          <span class="f-w6 mb-0">Superオファー状況</span>-->
          <span class="f-w6 mb-0">Superオファー履歴</span>
          <div
            v-if="totalNotReadAds['apply'] > 0"
            class="notifi position-absolute"
          ></div>
        </div>
        <div class="filter-blur position-absolute"></div>
      </div>
      <div class="border" />
      <div class="list-options-info f-w3">
        <div class="option">
          <router-link to="/my-page/my-profile">
            <div class="div option__image position-relative">
              <img
                class="option__div"
                src="~@/assets/image/icon_menu_mypage/icon-view-profile-active.svg"
              />
            </div>
            <div class="option__text option__text--height f-w3">
              プロフィー ルを見る
            </div>
          </router-link>
        </div>
        <div class="option">
          <router-link to="/my-page/profile">
            <div class="div position-relative">
              <img
                class="option__div"
                src="~@/assets/image/icon_menu_mypage/icon-edit-profile-active.svg"
              />
            </div>
            <div class="option__text option__text--height">
              プロフィー ル編集
            </div>
          </router-link>
        </div>
        <div class="option">
          <router-link to="/my-page/points/buy">
            <div class="div position-relative">
              <img
                class="option__div"
                src="~@/assets/image/icon_menu_mypage/icon-buy-active.svg"
              />
            </div>
            <div class="option__text">ポイント購入</div>
          </router-link>
        </div>
        <div class="option" @click="nextPointHistory">
          <div class="div position-relative">
            <img
              class="option__div"
              src="~@/assets/image/icon_menu_mypage/PP-active.svg"
            />
          </div>
          <div class="option__text">ポイント履歴</div>
        </div>
        <div class="option">
          <router-link to="/my-page/ranking">
            <div class="div position-relative">
              <img
                class="option__div"
                src="~@/assets/image/icon_menu_mypage/icon-rank-active.svg"
              />
            </div>
            <div class="option__text">ランキング</div>
          </router-link>
        </div>
        <div class="option">
          <router-link to="/my-page/tracks">
            <div class="div">
              <div class="position-relative">
                <img
                  class="option__div"
                  src="~@/assets/image/icon_menu_mypage/icon-golf-active.svg"
                />
                <div
                  v-if="totalNotReadFootprint > 0"
                  class="number-not-read position-absolute"
                  :class="
                    totalNotReadFootprint >= 100
                      ? 'notify-round'
                      : 'notify-circle'
                  "
                >
                  {{ totalNotReadFootprint }}
                </div>
              </div>
            </div>
            <div class="option__text">足跡</div>
          </router-link>
        </div>
        <ModalLayout ref="shareApp" :defaultStyle="false">
          <invite-friend @showNotification="showNotification" />
        </ModalLayout>
        <div class="option" @click="$refs.shareApp.openModal()">
          <div class="div position-relative">
            <img
              class="option__div"
              src="~@/assets/image/icon_menu_mypage/icon-share-active.svg"
            />
          </div>
          <div class="option__text">シェア</div>
        </div>
        <div class="option">
          <router-link to="/my-page/blocked">
            <div class="div position-relative">
              <img
                class="option__div"
                src="~@/assets/image/icon_menu_mypage/icon-block-men-active.svg"
              />
            </div>
            <div class="option__text">ブロック</div>
          </router-link>
        </div>
        <div class="option">
          <router-link to="/my-page/setting">
            <div class="div position-relative">
              <img
                class="option__div"
                src="~@/assets/image/icon_menu_mypage/icon-setting-active.svg"
              />
            </div>
            <div class="option__text">設定</div>
            <!--            <div class="option__text">通知設定</div>-->
          </router-link>
        </div>
        <div class="option">
          <router-link to="/my-page/help">
            <div class="div position-relative">
              <img
                class="option__div"
                src="~@/assets/image/icon_menu_mypage/icon-mypage-active.svg"
              />
            </div>
            <div class="option__text">ヘルプ</div>
          </router-link>
        </div>
        <div class="option" @click="$refs.modalLogout.openModal()">
          <div class="div position-relative">
            <img
              class="option__div"
              src="~@/assets/image/icon_menu_mypage/icon-logout-active.svg"
            />
          </div>
          <div class="option__text">ログアウト</div>
        </div>
      </div>
      <ModalLayout ref="modalLogout">
        <p class="modal-logout-title">ログアウトしますか？</p>
        <div class="d-flex justify-content-center">
          <button @click.prevent="logOut" class="btn-logout">はい</button>
          <button @click="cancelLogOut" class="btn-cancel-logout">
            いいえ
          </button>
        </div>
      </ModalLayout>
      <ModalLayout ref="creditPopup" :defaultStyle="false">
        <createOrderPopup @closeModal="$refs.creditPopup.closeModal()" />
      </ModalLayout>
      <ModalLayout ref="creditIdentifyPopup" :defaultStyle="false">
        <IdentifyCrefitCardPopup
          @closeModal="$refs.creditIdentifyPopup.closeModal()"
        />
      </ModalLayout>
      <ModalLayout ref="identifyPopup" :defaultStyle="false">
        <IdentifyPopup
          @identify="identify"
          :identity_status="user_info.identity_status"
          :sex="user_info.sex"
        />
      </ModalLayout>
      <ModalLayout ref="addMonthlyPlanPopup">
        <AddMonthlyPlanPopup
          @close-modal="closeAddMonthlyPlanPopup"
          :routePage="'NotMessage'"
        />
      </ModalLayout>
    </div>
    <card-footer ref="footer" />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import UserSummary from "@/components/UserSummary.vue";
import InviteFriend from "@/components/InviteFriend.vue";
import CardFooter from "@/components/CardFooter.vue";
import CardHeader from "@/components/CardHeader";
import router from "@/router";
import Toast from "@/components/Toast";
import { mapActions, mapGetters } from "vuex";
import firebase from "firebase";
import createOrderPopup from "@/components/CreateOrderPopup.vue";
import IdentifyCrefitCardPopup from "@/components/IdentifyCrefitCardPopup.vue";
import IdentifyPopup from "@/components/IdentifyPopup.vue";
import AddMonthlyPlanPopup from "@/components/AddMonthlyPlanPopup.vue";

export default {
  name: "MyPage",
  components: {
    "invite-friend": InviteFriend,
    "card-footer": CardFooter,
    "card-header": CardHeader,
    UserSummary,
    "notification-copy": Toast,
    createOrderPopup,
    IdentifyCrefitCardPopup,
    IdentifyPopup,
    AddMonthlyPlanPopup
  },
  metaInfo() {
    return {
      title: "マイページ",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: {
        text: "マイページ",
        isShowTitle: true,
        isShowLogo: false,
        back: null
      },
      user: null,
      isShowNotification: false,
      toastText: "コピーしました",
      typeSingle: { type: "single" }
    };
  },
  async created() {
    this.$store.dispatch("auth/setShowToast", false);
    await this.getInfoUser();
    await this.$store.dispatch("auth/getTotalNotReadAds");
    await this.$store.dispatch("auth/getTotalNotReadFootprint");
    await this.getPaymentMonthPacket();
    if (!this.user_info.post_code) {
      this.$store.dispatch("auth/setShowToast", true);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name == "Withdrawal" || from.name == "PointHistory") {
        vm.headerTitle.back = "/";
      } else {
        vm.headerTitle.back = "/";
      }
    });
  },
  computed: {
    ...mapGetters({
      user_info: "auth/user",
      isShowToast: "auth/isShowToast",
      cardInfo: "payment-stripe/cardInfo",
      totalNotReadFootprint: "auth/totalNotReadFootprint",
      totalNotReadAds: "auth/totalNotReadAds",
      limit: "areaLimit/limit_area",
      monthlyPlanStatus: "payment-stripe/monthlyPlanStatus"
    })
  },
  watch: {
    isShowToast(newVal) {
      if (newVal) {
        this.$toast(
          "プロフ画像, ニックネーム, 住所, ベストスコア等の必須項目をご入力ください",
          "通知",
          "success"
        );
        this.$store.dispatch("auth/setShowToast", false);
      }
    }
  },
  methods: {
    ...mapActions("payment-stripe", ["getPaymentMonthPacket"]),
    checkIdentityCreditCard() {
      if (this.limit > 0) {
        return;
      }
      if (!this.monthlyPlanStatus) {
        this.$refs.addMonthlyPlanPopup.openModal();
      } else {
        this.$router.push({
          name: "MenOrderList"
        });
      }
    },
    identify() {
      if (this.$route.name != "MenMyPageIdentifyProfile")
        this.$router.push({ name: "MenMyPageIdentifyProfile" });
      this.$refs.identifyPopup.closeModal();
      return;
    },
    async closeAddMonthlyPlanPopup() {
      this.$refs.addMonthlyPlanPopup.closeModal();
    },
    nextPointHistory() {
      this.$root.$refs.loading.start();
      router.push({
        name: "PointHistory",
        query: { currentPage: 1, perPage: 12, type: 1 }
      });
      this.$root.$refs.loading.finish();
    },
    logOut() {
      this.$root.$refs.loading.start();
      Cookies.remove("prego_token");
      firebase.auth().signOut();
      this.$router.push({ name: "TopRegister" });
      this.$root.$refs.loading.finish();
    },
    cancelLogOut() {
      this.$refs.modalLogout.closeModal();
    },
    async getInfoUser() {
      this.user_info.isShowButton = true;
    },
    showNotification() {
      this.isShowNotification = true;
      setTimeout(() => {
        this.isShowNotification = false;
      }, 3000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/men/myPage.scss";
@import "~@/assets/scss/myPage.scss";
@import "~@/assets/scss/_fontFamily.scss";
.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding: 57px 19px 100px 19px;
  padding-top: 89px !important;
  // height: calc(100vh - 108px);
}
@media screen and (min-width: 1200px) {
  .content-scroll {
    padding: 80px 19px 150px 19px;
    padding-top: 112px !important;
    // height: calc(100vh - 152px);
  }
}
.wrapper-btn {
  width: 500px;
  margin: 0 auto;
}
@media screen and (max-width: 520px) {
  .wrapper-btn {
    width: auto;
  }
  .my-icon {
    width: auto;
  }
  .btn-order {
    margin-right: 10px !important;
  }
}

.notifi {
  border-radius: 50%;
  background-color: #eb5050;
  height: 20px;
  width: 20px;
  top: -3px;
  right: -3px;
  // right: 50%;
  // transform: translateX(calc(50% + 102px));
  z-index: 100;
}

@media only screen and (max-width: 376px) {
  .app-modal.active {
    overflow: auto;
    padding-top: 70px;
  }
}
</style>
